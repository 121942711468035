import * as React from 'react';
import { useState, useEffect }  from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import alterna from '../media/Alterna.png'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";


export default function LoginCard() {

const { instance } = useMsal();
const { accounts } = useMsal();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>

      <CardMedia
        component="img"
        height="194"
        image={alterna}
        alt="Paella dish"
      />
      <CardContent>
      <UnauthenticatedTemplate>
      <button onClick={() => instance.loginRedirect(loginRequest)}>
                            Login
                        </button>
                        </UnauthenticatedTemplate>
        </CardContent>


    </Card>
  );
}