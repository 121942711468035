import React, { useState, useEffect } from "react";
import logo from '../logo.svg';
import '../App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import LoginCard from "./LoginCard";

const Home = () => {
    const { instance } = useMsal();
    const { accounts } = useMsal();
    //in case you need to access the userinfo for further processing
    //console.log(accounts);
    //console.log(accounts[0]?.username);

    return (
        <>
            <UnauthenticatedTemplate>
                <div className="App">
                    <header className="App-header">
                        <LoginCard />
                    </header>
                </div>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <div className="App">
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo" />

                        <p style={{color:"darkblue"}}>
                            Hello {accounts[0]?.name}!
                        </p>
                        <a href="https://clientportaltest.alternacapitalsolutions.com/MicrosoftIdentity/Account/SignIn">
                        <button  className="btn btn-lg btn-primary">
                            Go to New Portal
                        </button>
                        </a>

                        <button className="btn btn-lg btn-primary" style = {{marginTop:"3vh"}} onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}>
                            Logout
                        </button>
                        {/* <a href="https://clientportaltest.alternacapitalsolutions.com/MicrosoftIdentity/Account/SignIn">
                        <button >
                            Go to New Portal
                        </button>
                        </a> */}

                    </header>
                </div>
            </AuthenticatedTemplate>
        </>
    )
}

export default Home;