import './App.css';
import React, { useState, useEffect } from "react";
import { MsalProvider} from "@azure/msal-react";
import Home from './components/Home';
import Header from './components/Header.js';
import './bootstrap.min.css'
export default function App({ msalInstance }) {

  return (<>
    <MsalProvider instance={msalInstance}>
      <Header/>
        <Home />
    </MsalProvider>
    </>

  );
}